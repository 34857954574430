import { OrganizationDetailsChannels } from '../components/Organization/Details/Channel';

export function OrganizationChannelsRoute() {
  return <OrganizationDetailsChannels />;
}

export const Component = OrganizationChannelsRoute;

export function clientLoader() {
  return null;
}
